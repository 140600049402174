import EmrBasePage from "../modules/EmrBasePage";

class EmrDefaultPage extends EmrBasePage {
    // class EmrDefaultPage extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
    }

    onReady() {
        super.onReady();
    }
}

new EmrDefaultPage();
